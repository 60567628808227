import {createSlice} from '@reduxjs/toolkit'

const initialUser = () => {
    // TODO BURADA DÜZENLEMELER YAPILACAK
    const item = window.localStorage.getItem('userData')
    //let item = null
    /*AuthService.getMeDetails().then(returnMeDetail => {
        item = returnMeDetail
    })*/
    //const item = window.localStorage.getItem('userData')
    //alert("initialUser")
    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : {}
}

export const userDataSlice = createSlice({
    name: 'userData',
    initialState: {
        userData: initialUser()
    }
})

export default userDataSlice.reducer
